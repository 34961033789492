<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { ref, computed, useElementHover, useNuxtApp } from '#imports';

interface IProps {
  method?: 'hover' | 'click';
  position?: 'top' | 'bottom' | 'left' | 'right';
  variant?: 'default' | 'primary' | 'clear';
}

const props = withDefaults(defineProps<IProps>(), {
  position: 'top',
  method: 'hover',
  variant: 'default',
});

const popoverButtonRef = ref<HTMLElement | null>(null);
const panel = ref<HTMLElement | null>(null);

const isHovered = useNuxtApp().ssrContext ? false : useElementHover(popoverButtonRef);

const panelClass = computed(() => {
  if (props.position === 'top' || props.position === 'bottom') {
    switch (props.variant) {
      case 'default':
        return `before:!border-y-gray-darkest before:border-x-transparent bg-gray-darkest`;

      case 'primary':
        return `before:!border-y-primary before:border-x-transparent bg-primary`;

      case 'clear':
        return `before:!border-y-white before:border-x-transparent bg-white`;
    }
  } else if (props.position === 'left' || props.position === 'right') {
    switch (props.variant) {
      case 'default':
        return `before:!border-x-gray-darkest before:border-y-transparent bg-gray-darkest`;

      case 'primary':
        return `before:!border-x-primary before:border-y-transparent bg-primary`;

      case 'clear':
        return `before:!border-x-white before:border-y-transparent bg-white`;
    }
  }
});

const positionClass = computed(() => {
  switch (props.position) {
    case 'top':
      return 'top bottom-full -translate-y-20 -translate-x-1/2 left-1/2';

    case 'bottom':
      return 'bottom top-full translate-y-20 -translate-x-1/2 left-1/2';

    case 'left':
      return 'left top-1/2 -translate-y-1/2 -translate-x-[calc(50%_+_20px)] -left-1/2';

    case 'right':
      return 'right top-1/2 -translate-y-1/2 translate-x-[calc(50%_-_20px)]';
  }
});
</script>
<template>
  <Popover
    v-slot="{ open }"
    class="relative"
  >
    <PopoverButton
      ref="popoverButtonRef"
      as="button"
      type="button"
    >
      <slot name="head" />
    </PopoverButton>

    <template v-if="method === 'hover' ? isHovered : open">
      <PopoverPanel
        ref="panel"
        as="div"
        static
        :class="['panel absolute z-20 rounded', panelClass, positionClass]"
      >
        <slot name="panel" />
      </PopoverPanel>
    </template>
  </Popover>
</template>

<style scoped lang="scss">
.panel {
  &.bottom {
    &::before {
      @apply absolute left-1/2 -translate-x-1/2 -translate-y-full top-0 z-10 border-b-[7px] border-x-[7px] border-t-0;

      content: '';
    }
  }

  &.top {
    &::before {
      @apply absolute left-1/2 -translate-x-1/2 translate-y-full bottom-0 z-10 border-t-[7px] border-x-[7px] border-b-0;

      content: '';
    }
  }

  &.left {
    &::before {
      @apply absolute top-1/2 -translate-y-1/2 translate-x-full right-0 z-10 border-y-[7px] border-l-[7px] border-r-0;

      content: '';
    }
  }

  &.right {
    &::before {
      @apply absolute top-1/2 -translate-y-1/2 -translate-x-full left-0 z-10 border-y-[7px] border-r-[7px] border-l-0;

      content: '';
    }
  }
}
</style>
